<template>
    <div class="profit-area  pd-bottom-100" style="padding-top: 50px;">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7">
                    <div class="section-title text-center">
                        <h4 class="b-animate-2 title arabickufi">نتيجة البحث</h4>
                        <!-- <p class="content arabic-kufi ">استمتع بأكثر من 100 لعبة جديدة ومتنوعة</p> -->
                    </div>
                </div>
            </div>
            <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                <div class="production-area bg-cover  pd-bottom-70">
                    <div class="container">
                        <div class="row row--grid " style="margin-right: 5px; margin-left: 5px;">
                            <div class="col-6 col-sm-4 col-lg-3 col-xl-3 arabicKufi" v-for="publish in searchData.slice(0,12)" :key="publish.id">
                                <div class="card card_all" >
                                    <a @click="getFeed(publish)" class="card__cover pointer">
                                        <img v-lazy="publish.cover" alt="">
                                        <svgVideos />
                                    </a>                  
                                    <ul class="card__list pointer">
                                        <li>{{ publish.name }}</li>
                                        <li>{{ publish.createDate.substring(0,10) }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { useCookie } from 'vue-cookie-next'
import { ref } from '@vue/reactivity';
import { useRouter, useRoute } from "vue-router"
import { onMounted } from '@vue/runtime-core';
import svgVideos from "@/components/svgData/svgVideos.vue";
export default {
    props: {
       search_id: String,
    },
    components: { 
       svgVideos
    },
    setup(props) {
      const cookie = useCookie()
      const router = useRouter();
      const route = useRoute();
      const searchData = ref([]);
      const GetSearch = async () => {
          await HTTP.get('GetSearchContent.php?search='+ props.search_id).then((res) => {
              searchData.value = res.data.Content;
           })
      }
      onMounted(() => {
          GetSearch();
      });
      const GetContent = (publish) => {
        router.push({ name: "Content", params: { content_id: publish.id } });
      };
      return {
        searchData,
        GetContent
      };
  },
}
</script>

<style>
</style>